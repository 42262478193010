import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { Actions } from '../../components/library'

function actions() {
    return (
        <LibraryWrapper>
            <Actions 
                currentStep={1}
                totalSteps={5}
                submitTo="/components"
                submitLabel="Next"
            />
            <hr />
            <Actions 
                dismissOnClick={e => console.log('BACK')}
                dismissLabel="Back"
                submitOnClick={e => console.log('NEXT')}
                submitLabel="Next"
                currentStep={2}
                totalSteps={5}
            />
            <hr />
            <Actions 
                dismissTo="/components"
                dismissLabel="Back"
                dismissDisabled={true}
                
                submitOnClick={e => console.log('SUBMIT')}
                submitLabel="Submit"
                currentStep={3}
                totalSteps={5}
            />
            <hr />
        </LibraryWrapper>
    )
}
export default actions
